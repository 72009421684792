import React, { useContext } from "react"
import { Box, Typography, Avatar, Hidden, Link } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useHistory, useLocation } from 'react-router-dom'
import LanguageContext from "./context/LanguageContext"
import recipes from "./data/recipes"

const useStyles = makeStyles(theme => {
  return {
    headerMenu: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      height: '50px',
      padding: '15px'
    },
    flagMenu: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '150px',
      paddingRight: theme.spacing(2)
    },
    headerImage: {
      [theme.breakpoints.up('xs')]: {
        background: 'url(/header_xs.jpg) no-repeat center',
        height: '241px'
      },
      [theme.breakpoints.up('sm')]: {
        background: 'url(/header_sm.jpg) no-repeat center',
        height: '375px'
      },
      [theme.breakpoints.up('md')]: {
        background: 'url(/header_md.jpg) no-repeat center',
        height: '482px'
      },
      [theme.breakpoints.up('lg')]: {
        background: 'url(/header_lg.jpg) no-repeat center',
        height: '500px'
      }
    },
    flagAvatar: {
      width: theme.spacing(3),
      height: theme.spacing(3),
      cursor: 'pointer'
    }
  }
})

const urlType = {
  faq: {
    sv: '/faq',
    en: '/en/faq',
    pl: '/pl/faq'
  },
  recipy: {
    sv: '/recept',
    en: '/en/recipes',
    pl: '/pl/recepty'
  },
  root: {
    sv: '/',
    en: '/en',
    pl: '/pl'
  }
}

const findCorrespondingRecipe = (recipeName, language) => {
  const recipe = recipes.find(r => {
    return Object.entries(r.identifiers).some(id => {
      return id[1] === recipeName
    })
  })

  return recipe.identifiers[language]
}

const transformToNewLanguageLocation = (currentUrl, newLanguage) => {
  const currentUrlType = Object.entries(urlType).find(
    type => {
      return Object.entries(type[1]).some(url => currentUrl.startsWith(url[1]))
    }
  )[0]

  if (currentUrlType === 'root' || currentUrlType === 'faq') {
    return urlType[currentUrlType][newLanguage]
  } else {
    const baseUrl = urlType[currentUrlType][newLanguage]
    const currenttRecipeName = currentUrl.split('/').pop()

    return `${baseUrl}/${findCorrespondingRecipe(currenttRecipeName, newLanguage)}`
  }

}

const Header = ({ hideBack }) => {
  const classes = useStyles()
  const history = useHistory()
  const languageContext = useContext(LanguageContext)
  const location = useLocation()

  return (
    <React.Fragment>
      <Box className={classes.headerMenu}>
        {hideBack
          ? <span />
          : (
            <Link onClick={() => history.push(languageContext.language === 'sv' ? '/' : `/${languageContext.language}`)}>
              <Typography style={{ cursor: 'pointer' }}>{languageContext.translationTexts.GENERAL.BACK_TO_RECIPY_PAGE}</Typography>
            </Link>
          )}
        <Hidden smDown>
          <Typography style={{ fontFamily: 'Caveat, cursive', fontSize: '57px' }}>{languageContext.translationTexts.GENERAL.HEADER}</Typography>
        </Hidden>
        <Box className={classes.flagMenu}>
          <Link onClick={() => history.push(languageContext.language === 'sv' ? '/faq' : `/${languageContext.language}/faq`)}>
            <Typography style={{ cursor: 'pointer' }}>FAQ</Typography>
          </Link>
          <Avatar alt="SV" src="/flags/se.png" className={classes.flagAvatar} onClick={() => history.push(transformToNewLanguageLocation(location.pathname, 'sv'))} />
          <Avatar alt="PL" src="/flags/pl.png" className={classes.flagAvatar} onClick={() => history.push(transformToNewLanguageLocation(location.pathname, 'pl'))} />
          <Avatar alt="GB" src="/flags/gb.png" className={classes.flagAvatar} onClick={() => history.push(transformToNewLanguageLocation(location.pathname, 'en'))} />
        </Box>
      </Box>
      <Box className={classes.headerImage}></Box>
    </React.Fragment>
  );
}

export default Header