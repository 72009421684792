import { Language } from "./types/languageTypes"

const changeTitle = (newTitle: string): void => {
  document.title = newTitle
}

const changePageDescription = (newDescription: string): void => {
  const descriptionMeta = document.querySelector('meta[name="description"]')

  if (descriptionMeta) {
    descriptionMeta.setAttribute("content", newDescription)
  } else {
    const newDescriptionMeta = document.createElement('meta')

    newDescriptionMeta.name = 'description'
    newDescriptionMeta.content = newDescription

    document.getElementsByTagName('head')[0].appendChild(newDescriptionMeta);
  }
}

const setPageLanguage = (language: Language) => {
  document.documentElement.lang = language;
}

interface HreflangMap {
  sv: string
  en: string
  pl: string
}

const createAndAddHreflangElement = (hreflang: string, href: string) => {
  const element = document.createElement('link')
  element.rel = 'alternate'
  element.hreflang = hreflang
  element.href = href

  document.getElementsByTagName('head')[0].appendChild(element);
}

const changeHreflang = (hreflangMap: HreflangMap) => {
  document.querySelectorAll('link[hreflang][rel="alternate"]').forEach(e => e.parentNode?.removeChild(e))

  const basePath: string = window.location.origin

  createAndAddHreflangElement(Language.SV, `${basePath}/${hreflangMap.sv}`)
  createAndAddHreflangElement(Language.EN, `${basePath}/${hreflangMap.en}`)
  createAndAddHreflangElement(Language.PL, `${basePath}/${hreflangMap.pl}`)
  createAndAddHreflangElement('x-default', `${basePath}/${hreflangMap.en}`)
}


export { changeTitle, changePageDescription, setPageLanguage, changeHreflang }