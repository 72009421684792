import { CircularProgress, Paper } from "@mui/material"
import { useEffect, useState } from "react"
import { useContext } from "react"
import LanguageContext from "../../context/LanguageContext"
import { API } from "aws-amplify"
import FAQ from "./FAQ"
import getListFAQSectionsGraphQLFor from '../../graphql/listFAQSections'
import { getAuthMode } from "../../common/auth"
import { changeHreflang, changePageDescription, changeTitle } from "../../common/webBrowserUtils"
import { useLocation } from "react-router"


const faqURLMap = {
  sv: 'faq',
  en: 'en/faq',
  pl: 'pl/faq'
}

const FAQContainer = () => {
  const languageContext = useContext(LanguageContext)
  const [faqSections, setFaqSections] = useState(null)
  const location = useLocation()

  async function fetchFAQ(language) {
    const authMode = await getAuthMode()

    const listFAQSections = getListFAQSectionsGraphQLFor(language)
    const faqData = await API.graphql({
      query: listFAQSections,
      authMode: authMode
    })
    setFaqSections(faqData.data.listFAQSections.items)
  }

  useEffect(() => {
    window.gtag('set', 'page_path', location.pathname);
    window.gtag('event', 'page_view');

    changeTitle(languageContext.translationTexts.FAQ.PAGE_TITLE)
    changePageDescription(languageContext.translationTexts.FAQ.META_DESCRIPTION)

    changeHreflang(faqURLMap[languageContext.language])

    fetchFAQ(languageContext.language)
  }, [languageContext])

  return (
    <Paper style={{ padding: '20px' }}>
      {faqSections ? <FAQ faqSections={faqSections} /> : <CircularProgress />}
    </Paper>
  )
}

export default FAQContainer