import React from 'react'
import { Language, TranslationTexts } from '../common/types/languageTypes'

import { translationTextsEN, translationTextsPL, translationTextsSE } from '../data/translationTexts'

const getTranslationText = (language: Language): TranslationTexts => {
  switch (language) {
    case Language.SV:
      return translationTextsSE
    case Language.EN:
      return translationTextsEN
    case Language.PL:
      return translationTextsPL
    default:
      console.log('Inget språk valt')
      return translationTextsSE
  }
}

interface LanguageContextType {
  language: Language
  translationTexts: TranslationTexts
}

const createLanguageContext = (language: Language): LanguageContextType => {
  let translationTexts = getTranslationText(language)

  return {
    language: language,
    translationTexts: translationTexts
  }
}

const LanguageContext: React.Context<LanguageContextType> = React.createContext(createLanguageContext(Language.SV))

export default LanguageContext
export { createLanguageContext }
export type { LanguageContextType }