import Auth from "@aws-amplify/auth"

async function getAuthMode() {
  try {
    await Auth.currentAuthenticatedUser()
    return 'AMAZON_COGNITO_USER_POOLS'
  } catch {
    return 'AWS_IAM'
  }
}

export { getAuthMode }