import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@mui/material"
import { ExpandMore } from "@mui/icons-material"
import React from "react"

interface QuestionsAndAnswers {
  order: number
  question: string
  answer: string
}

interface FAQSectionData {
  order: number
  sectionTitle: string
  questionsAndAnswers: QuestionsAndAnswers[]
}

interface FAQSectionProps {
  faqSection: FAQSectionData
  isFirst: boolean
}

const FAQSection = ({ faqSection, isFirst }: FAQSectionProps) => {
  return (
    <React.Fragment>
      <Typography variant='h4' style={{ margin: isFirst ? '0px 0px 20px 0px' : '40px 0px 20px 0px' }}>{faqSection.sectionTitle}</Typography>
      {
        faqSection.questionsAndAnswers
          .sort((qa1, qa2) => qa1.order - qa2.order)
          .map((qa, index) => (
            <Accordion key={index} onChange={event => {
              window.gtag('event', `FAQ Click: ${(event.target as HTMLElement).innerText}`, {
                event_category: 'FAQ Click',
                event_label: (event.target as HTMLElement).innerText
              })
            }}>
              <AccordionSummary expandIcon={<ExpandMore />}>
                <Typography style={{ fontWeight: 600 }} >{qa.question}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>{qa.answer}</Typography>
              </AccordionDetails>
            </Accordion>
          ))
      }
    </React.Fragment >
  )
}

interface FAQProps {
  faqSections: FAQSectionData[]
}

const FAQ = ({ faqSections }: FAQProps) => {
  return faqSections.length !== 0
    ? faqSections
      .sort((section1, section2) => section1.order - section2.order)
      .map((section, index) => (
        <FAQSection faqSection={section} key={index} isFirst={index === 0} />
      ))
    : (<Typography>Det fanns ingen konfigurerad FAQ data</Typography>)

}

export default FAQ