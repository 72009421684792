import React, { useEffect, useState } from "react"
import { Switch, Route } from "react-router-dom";
import { setPageLanguage } from "../common/webBrowserUtils"
import LanguageContext, { createLanguageContext } from "../context/LanguageContext"
import { Container } from "@mui/material"
import Header from "../Header"
import { makeStyles } from "@mui/styles";
import FAQContainer from "./faq/FAQContainer";
import RecipeGridContainer from "./recipeGrid/RecipeGridContainer";
import RecipePageContainer from "./recipe/RecipePageContainer";

const useStyles = makeStyles(theme => {
  return {
    mainContainer: {
      position: 'relative',
      padding: '0px',
      [theme.breakpoints.up('xs')]: {
        maxWidth: '450px',
        top: '-150px',
      },
      [theme.breakpoints.up('sm')]: {
        maxWidth: '700px',
        top: '-280px',
      },
      [theme.breakpoints.up('md')]: {
        maxWidth: '1000px',
        top: '-400px',
      }
    }
  }
})

const CookBookMain = ({ language }) => {
  const classes = useStyles()
  const [languageState, setLanguageState] = useState(createLanguageContext('sv'))

  useEffect(() => {
    setLanguageState(createLanguageContext(language))
    setPageLanguage(language)
  }, [language])

  return (
    <LanguageContext.Provider value={languageState}>
      <Switch>
        <Route exact path={['/', '/en', '/pl']}>
          <Header hideBack />
        </Route>
        <Route path={"*"}>
          <Header />
        </Route>
      </Switch>

      <Container className={classes.mainContainer}>
        <Switch>
          <Route path={['/faq', '/en/faq', '/pl/faq']}>
            <FAQContainer />
          </Route>
          <Route
            path={['/recept/:recipe', '/en/recipes/:recipe', '/pl/recepty/:recipe']}
            render={({ match }) => <RecipePageContainer id={match.params.recipe} />}
          />
          <Route path={"*"}>
            <RecipeGridContainer />
          </Route>
        </Switch>
      </Container>
    </LanguageContext.Provider>
  )
}

export default CookBookMain