interface TranslationTexts {
  GENERAL: {
    HEADER: string
    PAGE_TITLE: string
    BACK_TO_RECIPY_PAGE: string
  }

  RECIPYGRID_PAGE: {
    META_DESCRIPTION: string
  }

  RECIPY_PAGE: {
    INGREDIENTS: string
    START: string
    DONE: string
  }

  FAQ: {
    PAGE_TITLE: string
    META_DESCRIPTION: string
  }
}

enum Language {
  SV = 'sv',
  EN = 'en',
  PL = 'pl'
}

export { Language }
export type { TranslationTexts }