import { Divider, Grid, Paper, Typography } from '@mui/material'
import React, { useContext } from 'react'
import Timeline from '@mui/lab/Timeline'
import TimelineItem from '@mui/lab/TimelineItem'
import TimelineSeparator from '@mui/lab/TimelineSeparator'
import TimelineConnector from '@mui/lab/TimelineConnector'
import TimelineContent from '@mui/lab/TimelineContent'
import TimelineDot from '@mui/lab/TimelineDot'
import { TimelineOppositeContent } from '@mui/lab'
import RestaurantMenuIcon from '@mui/icons-material/RestaurantMenu'
import { LabelImportant, MenuBook } from '@mui/icons-material'
import LanguageContext from '../../context/LanguageContext'
import { makeStyles } from '@mui/styles'
import { RecipeType } from '../../common/types/recipyTypes'

const useStyles = makeStyles((theme) => {
  return {
    recipePaper: {
      padding: '16px'
    },
    recipeBox: {
      padding: '10px'
    },
    recipeImage: {
      width: '100%',
    },
    recipeDescriptionBox: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: '10px'
    }
  }
})

interface RecipePageProps {
  recipe: RecipeType
}

const RecipePage = ({ recipe }: RecipePageProps) => {
  const classes = useStyles()
  const languageContext = useContext(LanguageContext)

  const Ingredients = () => (
    <React.Fragment>
      <Typography variant="h5" gutterBottom>{languageContext.translationTexts.RECIPY_PAGE.INGREDIENTS}</Typography>
      {
        recipe[languageContext.language]?.ingredients.map((i, index) => (
          <React.Fragment key={index}>
            <Typography>{i}</Typography>
            <Divider />
          </React.Fragment>
        ))
      }
    </React.Fragment>
  )

  const Steps = () => (
    <React.Fragment>
      <Timeline style={{ margin: '0px' }}>
        <TimelineItem>
          <TimelineOppositeContent style={{ display: 'none' }} />
          <TimelineSeparator>
            <TimelineDot color="primary">
              <MenuBook />
            </TimelineDot>
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>
            <Typography style={{ fontWeight: 600 }}>{languageContext.translationTexts.RECIPY_PAGE.START}</Typography>
          </TimelineContent>
        </TimelineItem>
        {
          recipe[languageContext.language]?.steps.map((s, index) => (
            <TimelineItem key={index}>
              <TimelineOppositeContent style={{ display: 'none' }} />
              <TimelineSeparator>
                <TimelineDot>
                  <LabelImportant />
                </TimelineDot>
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>
                <Typography>{s}</Typography>
              </TimelineContent>
            </TimelineItem>
          ))
        }
        <TimelineItem>
          <TimelineOppositeContent style={{ display: 'none' }} />
          <TimelineSeparator>
            <TimelineDot color="primary">
              <RestaurantMenuIcon />
            </TimelineDot>
          </TimelineSeparator>
          <TimelineContent>
            <Typography style={{ fontWeight: 600 }}>{languageContext.translationTexts.RECIPY_PAGE.DONE}</Typography>
          </TimelineContent>
        </TimelineItem>
      </Timeline>
    </React.Fragment>
  )

  return (
    <Paper className={classes.recipePaper}>
      <Grid container>
        <Grid item sm={12} md={6} className={classes.recipeBox}>
          <img src={`${recipe?.img?.src}`} title={recipe?.img?.attribution} alt={recipe[languageContext.language]?.name} className={classes.recipeImage} />
        </Grid>
        <Grid item sm={12} md={6} className={classes.recipeDescriptionBox}>
          <Typography variant="h4" gutterBottom>{recipe[languageContext.language]?.name}</Typography>
          <Typography>{recipe[languageContext.language]?.description}</Typography>
        </Grid>
        <Grid item sm={12} md={4} className={classes.recipeBox}>
          <Ingredients />
        </Grid>
        <Grid item sm={12} md={8} className={classes.recipeBox}>
          <Steps />
        </Grid>
      </Grid>
    </Paper>
  )
}

export default RecipePage