import { Switch, Route, Redirect, BrowserRouter } from "react-router-dom";
import { ThemeProvider, StyledEngineProvider, createTheme, CircularProgress } from '@mui/material';
import React, { Suspense } from "react";
import CookBookMain from "./routes/CookBookMain";

const AdminContainer = React.lazy(() => import('./routes/admin/AdminContainer'));

const App = () => {
  const theme = createTheme();

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>

        <BrowserRouter>
          <Switch>
            <Route path="/admin">
              <Suspense fallback={<CircularProgress />}>
                <AdminContainer />
              </Suspense>
            </Route>
            <Route path="/en">
              <CookBookMain language='en' />
            </Route>
            <Route path="/pl">
              <CookBookMain language="pl" />
            </Route>
            <Redirect from="/sv" to="/" />
            <Route path="/">
              <CookBookMain language="sv" />
            </Route>
          </Switch>
        </BrowserRouter>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default App;
