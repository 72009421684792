import { TranslationTexts } from "../common/types/languageTypes"

const translationTextsSE: TranslationTexts = {
  GENERAL: {
    HEADER: 'Piotrs recept',
    PAGE_TITLE: 'Piotrs recept - Min personliga kokbok',
    BACK_TO_RECIPY_PAGE: 'Till receptlistan'
  },
  RECIPYGRID_PAGE: {
    META_DESCRIPTION: 'Mitt namn är Piotr och denna sida är min personliga kokbok som jag använder för att sammla ihop de recept som jag använder mig av.'
  },
  RECIPY_PAGE: {
    INGREDIENTS: 'Ingredienser',
    START: 'Start',
    DONE: 'Klart!'
  },
  FAQ: {
    PAGE_TITLE: 'FAQ | Piotrs recept - Min personliga kokbok',
    META_DESCRIPTION: 'Vad är syftet med denna sida? Vilka tekniker är det som används? Detta och mer kan du få svar på i denna FAQ.'
  }
}

const translationTextsEN: TranslationTexts = {
  GENERAL: {
    HEADER: 'Piotrs recipes',
    PAGE_TITLE: 'Piotrs recipes - My personal cookbook',
    BACK_TO_RECIPY_PAGE: 'To recipy page'
  },
  RECIPYGRID_PAGE: {
    META_DESCRIPTION: 'My name is Piotr and this page is my personal cookbook that I use to collect the recipes that I use.'
  },
  RECIPY_PAGE: {
    INGREDIENTS: 'Ingredients',
    START: 'Start',
    DONE: 'Done!'
  },
  FAQ: {
    PAGE_TITLE: 'FAQ | Piotrs recipes - My personal cookbook',
    META_DESCRIPTION: 'What is the purpose of this page? What techniques are used? This and more will be answered in this FAQ.'
  }
}

const translationTextsPL: TranslationTexts = {
  GENERAL: {
    HEADER: 'Przepisy Piotrka',
    PAGE_TITLE: 'Przepisy Piotrka - Moja osobista książka kucharska',
    BACK_TO_RECIPY_PAGE: 'Do strony z przepisami'
  },
  RECIPYGRID_PAGE: {
    META_DESCRIPTION: 'Nazywam się Piotr i ta strona to moja osobista książka kucharska, której używam do zbierania przepisów, których sam używam.'
  },
  RECIPY_PAGE: {
    INGREDIENTS: 'Składniki',
    START: 'Start',
    DONE: 'Gotowe!'
  },
  FAQ: {
    PAGE_TITLE: 'FAQ | Przepisy Piotrka - Moja osobista książka kucharska',
    META_DESCRIPTION: 'Jaki jest cel tej strony? Jakie techniki są używane? Na to i więcej odpowiemy w tym FAQ.'
  }
}

export { translationTextsSE, translationTextsEN, translationTextsPL }