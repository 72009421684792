import { RecipeType } from "../common/types/recipyTypes"

const recipes: RecipeType[] = [
    {
        identifiers: { sv: 'kall-fisksas', en: 'cold-fish-sauce', pl: 'zimny-sos-do-ryb' },
        tags: ['sås', 'sos', 'sauce', 'yoghurt', 'jogurt', 'yogurt', 'Bostongurka', 'fisk', 'ryba', 'fish', 'kall', 'zimny', 'cold', 'majonnäs', 'majonezu', 'mayonnaise'],
        img: {
            src: '/foodImage/white_sauce.jpg',
            previewSrc: '/prevFoodImage/white_sauce.jpg',
            attribution: 'Rainer Zenz, CC BY-SA 3.0 <http://creativecommons.org/licenses/by-sa/3.0/>, via Wikimedia Commons, https://upload.wikimedia.org/wikipedia/commons/2/23/Cacik-1.jpg'
        },
        nrOfportions: 2,
        sv: {
            name: 'Kall fisksås',
            previewDescription: 'En enkel och god kall sås till fisk',
            description: 'När jag gick i grundskolan så fick man alltid en kall fisksås till fiskpinnarna. Av någon anledningen så blev den en favoritdel av maträtten. Efter att jag slutade på grundskolan så åt jag inte den på ett par år tills jag tog och letade upp ett liknande recept på nätet. Den är väldigt snabb och enkel att göra och förbereds med fördel en timme eller mer innan serveringen',
            ingredients: [
                '2 dl turkisk yoghurt',
                '0,5 dl Bostongurka',
                '2 msk majonnäs',
                '0,5 tsk salt'],
            steps: [
                'Mät upp alla ingredienserna och lägg de i en skål.',
                'Rör ihop ingredienserna till en jämn blandning.',
                'Låt såsen stå i kylen fram tills serveringen.'
            ]
        },
        en: {
            name: 'Cold fish sauce',
            previewDescription: 'A simple and tasty cold sauce for fish',
            description: 'When I was going to primary school, fish sticks where always served together with a cold sauce. For some reason, it became my favorite part of the dish. After I finished primary school, I did not eat it for a couple of years until I looked up a similar recipe online. It is very quick and easy to make and is preferably prepared an hour or more before serving.',
            ingredients: [
                '2 dl turkish yogurt',
                '0,5 dl Bostongurka',
                '2 tbsp mayonnaise',
                '0,5 tsp salt'],
            steps: [
                'Measure out all the ingredients and place them in a bowl.',
                'Stir the ingredients to a smooth mixture.',
                'Leave the sauce in the fridge until serving.'
            ]
        },
        pl: {
            name: 'Zimny sos dla ryby',
            previewDescription: 'Prosty i smaczny zimny sos do ryb.',
            description: 'Kiedy chodziłem do podstawówki, paluszki rybne zawsze były podawane razem z zimnym sosem. Z jakiegoś powodu stała się moją ulubioną częścią dania. Po ukończeniu szkoły podstawowej nie jadłem jej przez kilka lat, dopóki nie wyszukałem w Internecie podobnego przepisu. Jest bardzo szybki i łatwy do przygotowania i najlepiej jest przygotować godzinę lub więcej przed podaniem.',
            ingredients: [
                '2 dl jogurt turecki',
                '0,5 dl Bostongurka',
                '2 łyżki majonezu',
                '0,5 łyżeczka soli'],
            steps: [
                'Odmierz wszystkie składniki i umieść je w misce.',
                'Wymieszaj składniki na gładką mieszankę.',
                'Sos pozostaw w lodówce do podania.'
            ]
        }
    },
    {
        identifiers: { sv: 'korv-stroganoff', en: 'stroganoffs-sausage', pl: 'kielbasa-stroganoffa' },
        tags: [
            'ris', 'ryż', 'rice', 'korv', 'kiełbasa', 'sausage', 'lök', 'cebula', 'onion', 'grädde', 'śmietany', 'cream',
            'dijon', 'senap', 'dijonsenap', 'mustard', 'musztarda', 'tomatpure', 'tomato', 'puree', 'przecier', 'pomidory'
        ],
        img: {
            src: '/foodImage/korv_stroganoff.jpg',
            previewSrc: '/prevFoodImage/korv_stroganoff.jpg',
            attribution: 'Albin Olsson, CC BY 3.0 <https://creativecommons.org/licenses/by/3.0>, via Wikimedia Commons, https://upload.wikimedia.org/wikipedia/commons/2/2d/Korv_stroganoff.jpg'
        },
        nrOfportions: 4,
        sv: {
            name: 'Korv Stroganoff',
            previewDescription: 'En enkel och god favorit.',
            description: 'En enkel gryta som inte behöver introduktion. Simpel och smakrik.',
            ingredients: [
                '500 gram falukorv eller motsvarande',
                '1-2 gula lökar',
                'smör',
                '3 msk tomatpuré',
                '2 tsk dijonsenap',
                '2-4 dl grädde',
                'salt',
                'peppar',
                'Ris'
            ],
            steps: [
                'Hacka löken.',
                'Lägg i smör i en traktorpanna, värm upp och sedan lägg i den hackade löken.',
                'Medan du steker löken (och rör om den med jämna mellanrum), dra av skinnet av falukorven och skär den i lagom avlånga bitar.',
                'När löken början bli mjuk, lägg i falukorven och stek den till den får en fin stekytta (självklart under omrörning).',
                'När korven har börjat få stekyta, lägg i tomatpurén cch dijonsenapen och rör om.',
                'Späd ut det med grädde och rör om till såsen får en jämn färg.',
                'Smaksätt med salt och peppar och låt den koka i några minuter.',
                'Servera med ris som du kokar parrallelt med grytan.'
            ]
        },
        en: {
            name: 'Stroganoffs sausage',
            previewDescription: 'A simple and tasty favorite.',
            description: 'A simple stew that does not need introduction. Easy to do and tasty.',
            ingredients: [
                '500 gram falukorv or equivalent',
                '1-2 yellow onions',
                'butter',
                '3 tbsp tomato puree',
                '2 tsp dijon mustard',
                '2-4 dl cream',
                'salt',
                'pepper',
                'rice'
            ],
            steps: [
                'Chop the onion.',
                'Put butter in a tractor pan, heat up and then add the chopped onion.',
                'While you are frying the onion (and stirring it at regular intervals), peel the skin of the falukorv and cut it into reasonably oblong pieces.',
                'When the onion starts to soften, add the falukorv and fry it until it has a nice frying surface (of course while stirring)',
                'When the sausage has started to get a frying surface, add the tomato puree and Dijon mustard and stir.',
                'Dilute it with cream and stir until the sauce gets an even color.',
                'Season with salt and pepper and cook for a few minutes.',
                'Serve with rice that you cook in parallel with the stew.'
            ]
        },
        pl: {
            name: 'Kiełbasa stroganoffa',
            previewDescription: 'Prosty i smaczny faworyt.',
            description: 'Proste sanie, którego nie trzeba przedstawiać. Łatwe do zrobienia i smaczne.',
            ingredients: [
                '500 gram falukorv lub odpowiednik',
                '1-2 żółte cebule',
                'masło',
                '3 msk przecier pomidorow',
                '2 tsk musztarda Dijon',
                '2-4 dl śmietany',
                'sól',
                'pieprz',
                'ryż'
            ],
            steps: [
                'Kroić cebule.',
                'Włóż masło na patelnię traktora, podgrzej, a następnie dodaj posiekaną cebulę.',
                'Podczas smażenia cebuli (i mieszania jej w regularnych odstępach czasu), obierz skórkę falukorv i pokrój ją na dość podłużne kawałki.',
                'Kiedy cebula zacznie mięknąć, dodaj falukorv i smaż do uzyskania ładnej powierzchni do smażenia (oczywiście mieszając)',
                'Kiedy kiełbasa zacznie nabierać powierzchni do smażenia, dodać przecier pomidorowy i musztardę Dijon i wymieszać.',
                'Rozcieńczyć śmietaną i mieszać, aż sos nabierze jednolitego koloru.',
                'Dopraw solą i pieprzem i gotuj przez kilka minut.',
                'Podawaj z ryżem, który gotujesz równolegle z kiełbasą'
            ]
        }
    },
    /*
    Tsatsiki,
    Egg fried rice
    pankakor
    {
        ids: { se: 'pisto', en: 'pisto', pl: 'pisto' }
        name: 'Pisto',
        tags: [],
        prevImg: 'prevImage/flat.jpg',
        ingredients: [],
        steps: []
    },
    {
        id: 'Brunsås',
        name: 'Brunsås',
        tags: ['sås'],
        prevImg: 'prevImage/gryta.jpg',
        ingredients: [],
        steps: []
    },*/
    {
        identifiers: { sv: 'kottfarssas', en: 'swedish-bolognese', pl: 'szwedzki-bolognese' },
        tags: [],
        img: {
            src: '/foodImage/pasta_bolognese.jpg',
            previewSrc: '/prevFoodImage/pasta_bolognese.jpg',
            attribution: 'hozinja, CC BY 2.0 <https://creativecommons.org/licenses/by/2.0>, via Wikimedia Commons, https://upload.wikimedia.org/wikipedia/commons/c/c4/Spaghetti_bolognese_%28hozinja%29.jpg'
        },
        nrOfportions: 4,
        sv: {
            name: 'Köttfärssås',
            previewDescription: 'En klassiker, men med extra sås än de andra recepten jag har sett.',
            description: 'En riktig svenska klassiker. Har dock tyckt att det varit lite för lite sås när man gjort det enligt de recepten jag har sett samt inte så len som jag gillar det. Därför dels använder jag passerade tomater istället för krossade tomater, dels använder jag dubbel mängd passerade tomater än vad jag har sätt att man använder krossade tomater i de andra recepten. Ibland använder jag mer vitlök (ibland till och med ett halvt vitlökshuvud), andra gånger lägger jag in halv chillipaprika för att få upp hettan.',
            ingredients: [
                '400 gram nötfärs',
                '2 gula lökar',
                '2 vitlöksklyftor',
                '2 burkar (totalt 800 gram) passerade tomater',
                'olivolja',
                '1 tsk salt',
                '1 tsk socker',
                '1 krm svartpeppar',
                '2 tsk basilika',
                '2 tsk timjan',
                '2 tsk oregano'
            ],
            steps: [
                'Hacka lök och vitlök.',
                'Häll olivolja i en traktorpanna och värm upp den.',
                'Stek löken och vitlöken tills löken blir mjuk.',
                'När löken blivit mjuk, lägg i köttfärsen och stek den under omrörning tills man har delat upp den i tillräkligt små delar.',
                'Fortsätt gärna steka köttfärsen till den börjar få en fin stekyta.',
                'Tillsätt de passerade tomaterna tillsammans med socker, salt och peppar.',
                'Blanda ihop ingredienserna och låt köttfärssåsen koka på svag värme i 20 minuter.',
                'Tillsätt basilika och låt den koka ytterligare i 10 minuter',
                'Tillsätt timjan och oregano och låt den eventuellt småputtra till pastan är klar.'
            ]
        },
        en: {
            name: 'Swedish bolognese',
            previewDescription: 'A classic, but with extra sauce than the other recipes I\'ve seen.',
            description: 'A real Swedish classic. However, I thought it was too little sauce when you made it according to the recipes I have seen and not as smooth as I like it. Therefore, I use passed tomatoes instead of crushed tomatoes, and I use twice the amount of passed tomatoes than I have used crushed tomatoes in the other recipes. Sometimes I use more garlic (even up to half a garlic head), other times I add half a chilli pepper to get the heat up.',
            ingredients: [
                '400 grams of ground beef',
                '2 yellow onions',
                '2 cloves of garlic',
                '2 cans (800 grams in total) passed tomatoes',
                'olive oil',
                '1 tsp salt',
                '1 tsp sugar',
                '1 ml black pepper',
                '2 tsp basil',
                '2 tsp thyme',
                '2 tsp oregano'
            ],
            steps: [
                'Chop onion and garlic.',
                'Pour olive oil into a tractor pan and heat it up.',
                'Fry the onion and garlic until the onion is soft.',
                'When the onion has become soft, add the minced meat and fry it while stirring until it has been divided into sufficiently small parts.',
                'Feel free to continue frying the minced meat until it starts to get a nice frying surface.',
                'Add the passed tomatoes together with sugar, salt and pepper.',
                'Mix the ingredients and let the minced meat sauce cook on low heat for 20 minutes.',
                'Add basil and cook for another 10 minutes',
                'Add the thyme and oregano and let it eventually cook until the pasta is ready.'
            ]
        },
        pl: {
            name: 'Szwedzki bolognese',
            previewDescription: 'Klasyka, ale z dodatkowym sosem niż w innych przepisach, które widziałem.',
            description: 'Prawdziwa szwedzka klasyka. Jednak myślałem, że to za mało sosu, kiedy robiłem go według przepisów, które widziałem, i nie tak gładkie, jak lubię. Dlatego używam przetartych pomidorów zamiast rozdrobnionych pomidorów i używam dwa razy więcej przetartych pomidorów niż w innych przepisach. Czasem dodaję więcej czosnku (nawet do połowy główki czosnku), innym razem dodaję pół papryczki chilli na podgrzanie.',
            ingredients: [
                '400 gramów mielonej wołowiny',
                '2 żółte cebule',
                '2 ząbki czosnku',
                '2 puszki (w całości 800 gram) przetartych pomidorów',
                'Oliwa z oliwek',
                '1 łyżeczka soli',
                '1 łyżeczka cukru',
                '1 ml pieprzu czarnego',
                '2 łyżeczki bazylii',
                '2 łyżeczki tymianku',
                '2 łyżeczki oregano'
            ],
            steps: [
                'Posiekaj cebulę i czosnek.',
                'Wlej oliwę do patelnię traktora i podgrzej ją.',
                'Smażyć cebulę i czosnek, aż cebula będzie miękka.',
                'Kiedy cebula zmięknie, dodaj mięso mielone i smaż mieszając, aż zostanie podzielona na wystarczająco małe części.',
                'Możesz kontynuować smażenie mięsa mielonego, aż zacznie nabierać przyjemnej powierzchni do smażenia.',
                'Dodaj przetartych pomidory razem z cukrem, solą i pieprzem.',
                'Wymieszaj składniki i gotuj sos z mielonego mięsa na małym ogniu przez 20 minut.',
                'Dodaj bazylię i gotuj przez kolejne 10 minut',
                'Dodaj tymianek i oregano i ewentualnie gotuj, aż makaron będzie gotowy.'
            ]
        }
    },
    {
        identifiers: { sv: 'pumpsoppa', en: 'pumpkin-soup', pl: 'zupa-dyniowa' },
        tags: ['Butternut', 'soppa', 'zupa', 'soup', 'pumpa', 'dynia', 'pumpkin', 'lök', 'cebula', 'onion', 'morot', 'carrot', 'marchewka', 'potatis', 'ziemiak', 'potato', 'purjolök', 'leek', 'por'],
        img: {
            src: '/foodImage/pumpkin_soup.jpg',
            previewSrc: '/prevFoodImage/pumpkin_soup.jpg',
            attribution: 'cyclonebill, CC BY-SA 2.0 <https://creativecommons.org/licenses/by-sa/2.0>, via Wikimedia Commons, https://upload.wikimedia.org/wikipedia/commons/8/85/Flickr_-_cyclonebill_-_Gr%C3%A6skarsuppe_med_chili.jpg'
        },
        nrOfportions: 0,
        sv: {
            name: 'Pumpsoppa',
            previewDescription: 'En god soppa gjord på Butternutpumpa',
            description: 'En enkel och god soppa baserat på Butternutpumpa. Detta recept fick jag från en vän, dock har vi olika åsikter om ifall detta är en soppa eller pure 😆. Passar bra med bröd.',
            ingredients: [
                '300 fram Butternutpumpa',
                'Vita delen av en purjolök',
                'Halv stor gul lök',
                '1 morot',
                '1-2 potatisar',
                'Två matskedar olivolja',
                'Salt',
                'Peppar'
            ],
            steps: [
                'Hacka löken, purjolöken och moroten.',
                'Skala pumpan och potatisen och hacka de i 3-4 cm stora kuber.',
                'Häll två matskedar olivolja i en traktorpanna eller kastrul och stek den hackade löken, purjolöken och moroten i 5 minuter på medium värme',
                'Sänk sedan till låg värme, lägg på ett lock och forsätt stecka det i 15 minuter. Se till att det finns tillräkligt med olja så att grönsakerna inte bränns.',
                'Lägg i den hackade pumpan och potatisen och blanda ihop allt i pannan.',
                'Häll i vatten så att du täcker allt i pannan.',
                'Koka i 25 minuter.',
                'Smaka av med salt och peppar',
                'Mixa allt med en stavmixer.'
            ]
        },
        en: {
            name: 'Pumpkin soup',
            previewDescription: 'A tasty soup made from Butternut pumpkin',
            description: 'A simple and tasty soup based on Butternut pumpkin. I got this recipe from a friend, however, we have different opinions about whether this is a soup or pure 😆. Fits well with bread.',
            ingredients: [
                '300 gram Butternut pumphin',
                'The white part of the leek',
                'Half of a big yellow onion',
                '1 carrot',
                '1-2 potatoes',
                'two spoons of olive oil',
                'Salt',
                'Pepper'
            ],
            steps: [
                'Chop the onion, leek and the carrot.',
                'Peel the pumpkin and potatoes and chop them into 3-4 cm large cubes.',
                'Pour two tablespoons of olive oil into a tractor pan or pot and fry the chopped onion, leek and carrot for 5 minutes on medium heat.',
                'Then lower to low heat, put on a lid and continue to fry it for 15 minutes. Make sure there is enough oil so that the vegetables do not burn.',
                'Add the chopped pumpkin and potatoes and mix everything in the pan.',
                'Pour in water so that you cover everything in the pan.',
                'Cook for 25 minutes.',
                'Season with salt and pepper.',
                'Mix everything with a hand mixer.'
            ]
        },
        pl: {
            name: 'Zupa dyniowa',
            previewDescription: 'En god soppa gjord på Butternutpumpa',
            description: 'En enkel och god soppa baserat på Butternutpumpa. Detta recept fick jag från en vän, dock har vi olika åsikter om ifall detta är en soppa eller pure :)',
            ingredients: [
                '300 gram Dyni piżmowej',
                'Biała część pora',
                'Połowa dużej żółtej cebuli',
                '1 marchewka',
                '1-2 ziemiaki',
                'Dwie łyżki oliwy z oliwek',
                'Sól',
                'Pieprz'
            ],
            steps: [
                'Posiekaj cebulę, por i marchew.',
                'Dynię i ziemniaki obrać i pokroić w kostki o wielkości 3-4 cm.',
                'Wlej dwie łyżki oliwy z oliwek na patelnię traktora lub garnka i smaż posiekaną cebulę, por i marchewkę przez 5 minut na średnim ogniu.',
                'Następnie obniż ogień, przykryj pokrywką i smaż dalej przez 15 minut. Upewnij się, że jest wystarczająco dużo oleju, aby warzywa się nie przypaliły.',
                'Dodaj posiekaną dynię i ziemniaki i wszystko wymieszaj na patelni.',
                'Wlej wodę, aby zakryć wszystko na patelni.',
                'Gotuj przez 25 minut.',
                'Doprawić solą i pieprzem.',
                'Wymieszaj wszystko mikserem ręcznym.'
            ]
        }
    },
    {
        identifiers: { sv: 'hamburgare', en: 'hamburger-en', pl: 'hamburger-pl' },
        tags: ['hamburgare', 'hamburger', 'grill'],
        img: {
            src: '/foodImage/hamburger.jpg',
            previewSrc: '/prevFoodImage/hamburger.jpg',
            attribution: 'Burgerlounge, CC BY-SA 4.0 <https://creativecommons.org/licenses/by-sa/4.0>, via Wikimedia Commons, https://upload.wikimedia.org/wikipedia/commons/0/07/Lounge_Burger_with_Bacon.jpg',
        },
        nrOfportions: 2,
        sv: {
            name: 'Hamburgare',
            previewDescription: 'Basrecept för hamburgarkött',
            description: 'Det enklaste receptet för hamburgarkött måste vara malen nöttkött (helst högrev), salt och peppar. Mer behövs inte för att det ska smaka gott. Helst ska det grillas.',
            ingredients: [
                '400 gram malen högrev',
                '1 tsk salt',
                '1 krm svartpeppar'
            ],
            steps: [
                'Blanda ihop samtliga ingredienser.',
                'Forma fyra hamburgare. Kan antingen göras för hand eller så kan man använda en hamburgarpress.',
                'Tillaga på medium värme. Hälst på grillen.'
            ]
        },
        en: {
            name: 'Hamburger',
            previewDescription: 'Basic recipe for hamburger meat',
            description: 'The simplest recipe for hamburger meat must be minced beef (preferably chuck), salt and pepper. No more is needed for it to taste good. Preferably it should be grilled.',
            ingredients: [
                '400 gram grinded chuck',
                '1 tsp salt',
                '1 ml czarnego pieprzu'
            ],
            steps: [
                'Mix all the ingredients together.',
                'Form four burgers. Can either be made by hand or you can use a hamburger press. ',
                'Cook on medium  heat. Preferably on the grill.'
            ]
        },
        pl: {
            name: 'Hamburger',
            previewDescription: 'Podstawowy przepis na mięso hamburgerowe',
            description: 'Najprostszym przepisem na mięso hamburgerowe musi być mielona wołowina (najlepiej karkówka), sól i pieprz. Nic więcej nie jest potrzebne, aby dobrze smakowało. Najlepiej grillować.',
            ingredients: [
                '400 gram mielonej karkówki',
                '1 łyżeczka soli',
                '1 ml pieprz'
            ],
            steps: [
                'Wymieszaj wszystkie składniki razem',
                'Uformuj cztery hamburgery. Może być wykonany ręcznie lub możesz użyć prasy do hamburgerów.',
                'Gotuj na średnim ogniu. Najlepiej na grillu.'
            ]
        }
    },
    {
        identifiers: { sv: 'overnight-oats-med-kakao', en: 'overnight-oats-with-cacao', pl: 'overnight-oats-z-kakao' },
        tags: ['overnight', 'natt', 'noc', 'oats', 'havre', 'owies', 'kakao', 'cacao'],
        img: {
            src: '/foodImage/overnight_oats_with_cacao.jpg',
            previewSrc: '/prevFoodImage/overnight_oats_with_cacao.jpg',
            attribution: '"Raspberry Chocolate Overnight Oats" by Veva Health is licensed with CC BY 2.0. To view a copy of this license, visit https://creativecommons.org/licenses/by/2.0/',
        },
        nrOfportions: 1,
        sv: {
            name: 'Overnight oats med kakao',
            previewDescription: 'Ett simpelt mellanmål med mycket smak av chokolad.',
            description: 'Detta är ett simpelt mellanmål med mycket smak av chokolad. Är en av mina standard mellanmål när jag ska träna och vill fylla på med energi innan.',
            ingredients: [
                '1 dl havregryn',
                '0,5 msk chiafrö',
                '1.5 dl havremjölk',
                '2 tsk kakao',
                '1 krm vaniljpulver'
            ],
            steps: [
                'Mätt upp havregrynen, chiafrön, kakaon och vaniljpulvret och blanda ihop de i en liten bäggare eller burk.',
                'Häll i havremjölken och blanda ordentligt',
                'Täck över och ställ in i kylen i minst 8 timmar'
            ]
        },
        en: {
            name: 'Overnight oats with cacao',
            previewDescription: 'This is a simple snack with a lot of chocolate flavor.',
            description: 'This is a simple snack with a lot of chocolate flavor. It is one of my standard snacks when I want to replenish my energy before a work out.',
            ingredients: [
            ],
            steps: [
            ]
        },
        pl: {
            name: 'Overnight oats z kakao',
            previewDescription: 'To prosta przekąska o mocno czekoladowym smaku.',
            description: 'To prosta przekąska o mocno czekoladowym smaku. To jedna z moich standardowych przekąsek, kiedy chcę uzupełnić energię przed treningiem.',
            ingredients: [
            ],
            steps: [
                'Odmierz płatki owsiane, nasion chia, kakao i proszek waniliowy i wymieszaj je razem w małym słoiczku.',
                "Wlej mleko owsiane i dobrze wymieszaj",
                "Przykryj i włóż do lodówki na co najmniej 8 godzin"
            ]
        }
    },
    {
        identifiers: { sv: 'chilli-con-carne-se', en: 'chilli-con-carne-en', pl: 'chilli-con-carne-pl' },
        tags: ['chilli', 'kött', 'meat', 'mięso', 'bönor', 'beans', 'fasola'],
        img: {
            src: '/foodImage/chilli-con-carne.jpg',
            previewSrc: '/prevFoodImage/chilli-con-carne.jpg',
            attribution: '"Julia\'s Chilli con carne with brown rice - close" by avlxyz is licensed with CC BY-NC-SA 2.0. To view a copy of this license, visit https://creativecommons.org/licenses/by-nc-sa/2.0/',
        },
        nrOfportions: 4,
        sv: {
            name: 'Chilli con carne',
            previewDescription: 'En god och smakrik gryta med lite hetta i.',
            description: 'Detta är en god och smakrik grytta med lite hetta i. Vissa ser den som inte auntentisk på grund av att den innehåller bönor, men det är så jag har alltid ätit den. Äts gärna med ris.',
            ingredients: [
                '1 stor lök',
                '1 röd paprika',
                '2 klyftor vitlök',
                '1 msk rapsolja',
                '2 tsk chillipulver',
                '1,5 tsk paprikapulver',
                '2 tsk mald spiskummin',
                '500 gram nöttfärs',
                '1 tärning köttbuljong',
                '300 ml varmt vatten',
                '400 gram passerade tomater',
                '1 tsk majeram',
                '1 tesktsked socker',
                '2 msk tomatpure',
                '400 gram kidneybönor',
                'Salt',
                'Svartpeppar'
            ],
            steps: [
                'Hacka löken, samtidigt värm upp en stekpanna med rapsolja.',
                'Hacka paprikan och vitlöken.',
                'Lägg i löken. Stek den under omröring.',
                'När löken börjar bli mjuk lägg i paprikan, vitlöken, chillipulvret, paprikapulvret och spiskumminen. Rör om det ordentligt och stek i runt 5 minuter under omröring.',
                'Eventuellt öka värmen och lägg i nöttfärsen. Hacka den med träspaden till den är i fina jämna bitar. värmen ska vara tillräkligt hög för att färsen ska stekas och inte kokas',
                'Lägg i en buljonkubb i 300 ml varmt vatten och blanda tills den har löst upp sig. När köttet är genomstekt så häller du in buljongen i pannan.',
                'Lägg i de passerade tomaterna, majeramen, sockret, tomatpuren, salt, peppar och blanda om ordentligt',
                'Lägg på lock och låt den puttra i 20 minuter.',
                'Skölj kidneybönorna och lägg i de i grytan, låt den puttra i ytterligare 10 minuter.',
                'Smaka av med salt, peppar och eventuellt mer kryddor, ta av den av spisen och låt den stå i 10 minuter.'
            ]
        },
        en: {
            name: 'Chilli con carne',
            previewDescription: 'A good and tasty stew with a little heat.',
            description: 'This is a good and tasty stew with a little heat . Some people see it as not authentic because it contains beans, but that\'s how I have always eaten it. Eat preferably with rice.',
            ingredients: [
                '1 large onion',
                '1 red pepper',
                '2 cloves of garlic',
                '1 tbsp rapeseed oil',
                '2 tsp chilli powder',
                '1.5 tsp paprika',
                '2 tsp ground cumin',
                '500 grams of minced beef',
                '1 beef stock cube',
                '300 ml hot water',
                '400 grams of passed tomatoes',
                '1 tsp majeram',
                '1 tsp sugar',
                '2 tspb tomato puree',
                '400 grams of kidney beans',
                'Salt',
                'Black pepper'
            ],
            steps: [
                'Chop the onion, while heating a frying pan with rapeseed oil.',
                'Chop the red pepper and garlic.',
                'Add the onion to the pan. Fry it while stirring. ',
                'When the onion starts to soften, add the paprika, garlic, chilli powder, paprika and cumin. Stir well and fry for about 5 minutes while stirring.',
                'Possibly increase the heat and add the minced beef. Chop it up with a wooden spatula until it is in fine even pieces. the heat must be high enough for the minced meat to be fried and not boiled ',
                'Put a cube of broth in 300 ml of warm water and mix until it has dissolved. When the meat is cooked through, pour the broth into the pan.',
                'Add the passed tomatoes, majoram, sugar, tomato puree, salt, pepper and mix well',
                'Put the lid on and let it simmer for 20 minutes.',
                'Rinse the kidney beans and put them in the pot, let it simmer for another 10 minutes.',
                'Taste with salt, pepper and possibly more spices, remove it from the stove and let it stand for 10 minutes.'
            ]
        },
        pl: {
            name: 'Chilli con carne',
            previewDescription: 'Dobry i smaczny chilli con carne z odrobiną ciepła.',
            description: 'To dobry i smaczny chilli con carne z odrobiną ciepła. Niektórzy uważają, że nie jest autentyczny, ponieważ zawiera fasolę, ale ja zawsze tak ją jadłem. Jedz najlepiej z ryżem.',
            ingredients: [
                '1 duża cebula',
                '1 czerwona papryka',
                '2 ząbki czosnku',
                '1 łyżka oleju rzepakowego',
                '2 łyżeczki chili w proszku',
                '1,5 łyżeczki papryki w proszku',
                '2 łyżeczki kminku mielonego',
                '500 gramów mielonej wołowiny',
                '1 kostka bulionu wołowego',
                '300 ml gorącej wody',
                '400 gram przetartych pomidorów',
                '1 łyżeczka majeramu',
                '1 łyżeczka cukru',
                '2 łyżeczki przecieru pomidorowego',
                '400 gramów kidney beans',
                'Sól',
                'Czarny pieprz'
            ],
            steps: [
                'Cebulę posiekaj, podgrzewając patelnię z olejem rzepakowym.',
                'Posiekaj czerwoną paprykę i czosnek.',
                'Dodaj cebulę na patelnię. Smaż mieszając.',
                'Gdy cebula zacznie mięknąć, dodaj paprykę, czosnek, chili w proszku, paprykę w proszku i kminek. Dobrze wymieszaj i smaż przez około 5 minut, mieszając.',
                'Ewentualnie zwiększ ogień i dodaj mieloną wołowinę. Posiekaj drewnianą szpatułką, aż będzie na drobne równe kawałki. ciepło musi być wystarczająco wysokie, aby mięso mielone było smażone, a nie gotowane.',
                'Wrzuć kostkę bulionu do 300 ml ciepłej wody i mieszaj, aż się rozpuści. Gdy mięso się upiecze, wlej bulion na patelnię.',
                'Dodaj przecieru pomidorowego, majeranek, cukier, przecier pomidorowy, sól, pieprz i dobrze wymieszaj.',
                'Załóż pokrywkę i gotuj przez 20 minut.',
                'Wypłucz fasolę i włóż ją do garnka, gotuj jeszcze przez 10 minut.',
                'Spróbuj solą, pieprzem i ewentualnie większą ilością przypraw, zdejmij z kuchenki i odstaw na 10 minut.'
            ]
        }
    }
]

export default recipes