export const listFAQSectionsAllLanguages = `
  query ListFAQSections(
    $filter: ModelFAQSectionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFAQSections(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        order
        seSectionTitle
        enSectionTitle
        plSectionTitle
        questionsAndAnswers {
          order
          seQuestion
          seAnswer
          enQuestion
          enAnswer
          plQuestion
          plAnswer
        }
      }
      nextToken
    }
  }
`

const listFAQSectionsSe = `
  query ListFAQSections(
    $filter: ModelFAQSectionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFAQSections(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        order
        sectionTitle: seSectionTitle
        questionsAndAnswers {
          order
          question: seQuestion
          answer: seAnswer
        }
      }
      nextToken
    }
  }
`

const listFAQSectionsEn = `
  query ListFAQSections(
    $filter: ModelFAQSectionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFAQSections(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        order
        sectionTitle: enSectionTitle
        questionsAndAnswers {
          order
          question: enQuestion
          answer: enAnswer
        }
      }
      nextToken
    }
  }
`

const listFAQSectionsPl = `
  query ListFAQSections(
    $filter: ModelFAQSectionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFAQSections(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        order
        sectionTitle: plSectionTitle
        questionsAndAnswers {
          order
          question: plQuestion
          answer: plAnswer
        }
      }
      nextToken
    }
  }
`

const languageToListFAQSectionsGraphQLMap = {
  sv: listFAQSectionsSe,
  en: listFAQSectionsEn,
  pl: listFAQSectionsPl
}

const getListFAQSectionsGraphQLFor = (language) => {
  return language ? languageToListFAQSectionsGraphQLMap[language] : listFAQSectionsAllLanguages
}

export default getListFAQSectionsGraphQLFor