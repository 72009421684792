import { Typography } from "@mui/material"
import { useContext, useEffect } from "react"
import { useLocation } from "react-router"
import { changeHreflang, changePageDescription, changeTitle } from "../../common/webBrowserUtils"
import LanguageContext from "../../context/LanguageContext"
import recipes from "../../data/recipes"
import RecipePage from "./RecipePage"

interface RecipePageContainerProps {
  id: string
}

declare global {
  interface Window { gtag: any; }
}

const RecipePageContainer = ({ id }: RecipePageContainerProps) => {
  const languageContext = useContext(LanguageContext)
  const location = useLocation()

  const recipe = recipes.find(r => {
    return Object.entries(r?.identifiers || {}).some(v => v[1] === id)
  })

  useEffect(() => {

    window.gtag('set', 'page_path', location.pathname);
    window.gtag('event', 'page_view');

    changeTitle(`${recipe ? recipe[languageContext.language]?.name : '...'} | ${languageContext.translationTexts.GENERAL.PAGE_TITLE}`)
    changePageDescription(recipe
      ? recipe[languageContext.language]?.description || ''
      : '')

    changeHreflang({
      sv: `recept/${recipe?.identifiers?.sv}`,
      en: `en/recipes/${recipe?.identifiers?.en}`,
      pl: `pl/recepty/${recipe?.identifiers?.pl}`
    })
  }, [languageContext, recipe])

  return recipe ? <RecipePage recipe={recipe} /> : <Typography>Hittade inte receptet</Typography>
}

export default RecipePageContainer