import React, { useContext, useEffect } from 'react'
import RecipeGrid from './RecipeGrid'
import RecipeSearch from './RecipeSearch'
import recipes from '../../data/recipes'
import { changeHreflang, changePageDescription, changeTitle } from '../../common/webBrowserUtils'
import LanguageContext from '../../context/LanguageContext'

const RecipeGridContainer = () => {
  const languageContext = useContext(LanguageContext)

  useEffect(() => {
    changeTitle(languageContext.translationTexts.GENERAL.PAGE_TITLE)
    changePageDescription(languageContext.translationTexts.RECIPYGRID_PAGE.META_DESCRIPTION)

    changeHreflang({
      sv: '',
      en: 'en',
      pl: 'pl'
    })
  }, [languageContext])

  return (
    <React.Fragment>
      {/*<RecipeSearch />*/}
      <RecipeGrid recipeList={recipes} />
    </React.Fragment>
  )
}

export default RecipeGridContainer