import { useContext } from 'react'
import {
    Grid,
    Paper,
    Card,
    CardActionArea,
    CardContent,
    CardMedia,
    Typography
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useHistory } from 'react-router-dom'
import LanguageContext from '../../context/LanguageContext';

const useStyles = makeStyles(theme => {
    return {
        recipeGridPaper: {
            padding: theme.spacing(2)
        },
        recipePaper: {
            height: '100%'
        }
    }
})

const createBaseRecipyUrl = (language) => {
    if (language === 'en') {
        return '/en/recipes'
    } else if (language === 'pl') {
        return '/pl/recepty'
    }

    return '/recept'

}

const createRecipyUrl = (recipe, language) => {
    return `${createBaseRecipyUrl(language)}/${recipe.identifiers[language]}`
}

const RecipeGrid = ({ recipeList }) => {
    const classes = useStyles()
    const history = useHistory()
    const theme = useTheme()
    const isMdAndUp = useMediaQuery(theme.breakpoints.up('sm'))
    const languageContext = useContext(LanguageContext)

    const gridSpacing = isMdAndUp ? 2 : 1

    return (
        <Paper className={classes.recipeGridPaper}>
            <Grid container spacing={gridSpacing}>
                {
                    recipeList.map(
                        (recipe, index) => (
                            <Grid key={index} item xs={6} sm={4} md={3}>
                                <Card className={classes.recipePaper}>
                                    <CardActionArea onClick={() => history.push(createRecipyUrl(recipe, languageContext.language))}>
                                        <CardMedia
                                            component="img"
                                            alt="No image found"
                                            image={recipe.img.previewSrc}
                                            title={recipe[languageContext.language].name}
                                        />
                                        <CardContent>
                                            <Typography gutterBottom variant="h6" component="h2" >
                                                {recipe[languageContext.language].name}
                                            </Typography>
                                            <Typography gutterBottom variant="body2" color="textSecondary" component="p">
                                                {recipe[languageContext.language].previewDescription}
                                            </Typography>
                                        </CardContent>
                                    </CardActionArea>
                                </Card>
                            </Grid>
                        ))
                }
            </Grid>
        </Paper>
    )
}

export default RecipeGrid